/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  getBrandAll({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/non-auth/brands", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  getBrandList({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/brand/all", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  getPopularBrands({commit}) {
    return new Promise((resolve, reject) => {
      axios
      .get("/api/v1/admins/brands/popular")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteBrandById({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/admins/brand/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  createBrand({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v1/admins/brand/create', data)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  getBrandDetailsBySlug({commit}, slug) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/brand/slug/${slug}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
  },
  getBrandDetails({commit}, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/brand/${id}`)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
  },
  updateBrandDetails({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/admins/brand/${data.id}`, data.info)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    })
  },
  getBrandProductList({commit}, { id, dataTableParams }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/admins/brand/${id}/products`, { params: dataTableParams })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
  },
  getBrandProductListBySlug({commit},  fitlerParams ) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/products-by-brand`, { params: fitlerParams })
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
  },
  removeProductFromBrand({commit}, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/api/v1/admins/brand/remove/${id}/product`)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
    });
  },
};
