/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  getStaffs({ commit }, orgId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/organization/${orgId}/users`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeStaffs({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/organization/${data.organizationId}/removeStaff/${data.userId}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPatients({ commit }, data) {
    let path = "/api/v1/organization/" + data.id + "/patients";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            search: data.search,
            clinic: data.clinic,
            clinicId: data.clinicId,
            userType: data.userType,
            sort: data.sort,
          },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  exportPatients({ commit }, data) {
    let path = "/api/v1/analytics/export-patients";
    
    return new Promise((resolve, reject) => {
        axios
        .get(path, { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
