import axios from '@/axios';

export default {
    fetchQuestionnaire({ commit },data) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/admins/questionnaire-management?page='+data.page+'&limit='+data.limit+'&search='+data.search+'&sort='+data.sort+'&dir='+data.dir)
              .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    fetchQuestionnaireDetails({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get('/api/v1/admins/questionnaire-management/'+id).then((res) => {
                resolve(res);
            }).catch(err =>{
                reject(err)
            });
        })
    },
    deleteQuestionnaire({commit},id){
      return new Promise((resolve,reject)=>{
        axios.delete('/api/v1/admins/questionnaire-management/'+id).then((res) => {
          resolve(res);
        }).catch(err =>{
          reject(err)
        });
      })
    },
    updateQuestionnaireDetails({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post('/api/v1/admins/questionnaire-management/update/'+data.id,data.info).then((res) => {
                resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
    },
  createQuestionnaire({commit},data){
    return new Promise((resolve,reject)=>{
      axios.post('/api/v1/admins/questionnaire-management/create',data.info).then((res) => {
        resolve(res);
      }).catch(err =>{
        reject(err)
      })
    })
  },
  fetchTreatmentPlans({commit}, data){
    return new Promise((resolve,reject)=>{
      axios.get('/api/v1/admins/questionnaire-management/treatments').then((res) => {
        resolve(res);
      }).catch(err =>{
        reject(err)
      })
    })
  },
  createCustomConsent({commit},data){
    return new Promise((resolve,reject)=>{
      axios.post('/api/v1/admins/custom-consent/create',data).then((res) => {
        resolve(res);
      }).catch(err =>{
        reject(err)
      })
    })
  },
  getCustomConsent({commit},id){
    return new Promise((resolve,reject)=>{
      axios.get('/api/v1/admins/custom-consent/'+id).then((res) => {
        resolve(res);
      }).catch(err =>{
        reject(err)
      })
    })
  },
}
