/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  MUTATE_ALL_FILTERS(state, filters) {
    state.filters = filters;
  },
  MUTATE_RESOURCES_SEARCH_RESULT(state, { data, isLoadMore }) {
    let results = data

    if (isLoadMore) results = {
      ...data,
      results: [
        ...state.resources.searchResults.results,
        ...data.results
      ]
    }

    state.resources.searchResults = results;
  },
  MUTATE_RESOURCES_RECENT(state, resources) {
    state.resources.recent = resources;
  },
  MUTATE_RESOURCES_COMPLIANCE(state, resources) {
    state.resources.compliance = resources;
  },
  MUTATE_RESOURCE_JSON_SETTING(state, settings) {
    state.settings = settings;
  },

}
