import axios from '@/axios';

export default {
    fetchPages({ commit },data) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/admins/page-management?page='+data.page+'&limit='+data.limit+'&search='+data.search+'&sort='+data.sort+'&dir='+data.dir)
              .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    fetchPageDetails({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get('/api/v1/admins/page-management/'+id).then((res) => {
                resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
    },
    updatePageDetails({commit},data){
        return new Promise((resolve,reject)=>{
            axios.post('/api/v1/admins/page-management/update/'+data.id,data.info).then((res) => {
                resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
    },

    fetchPageDetailsBySlug({commit},slug){
        return new Promise((resolve,reject)=>{
            axios.get('/api/v1/getPage?slug='+slug).then((res) => {
                resolve(res);
        }).catch(err =>{
            reject(err)
        })
    })
    },

}
