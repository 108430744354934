import mAxios from "@/axios";
import axios from "axios";
import { uniqBy, sortBy } from "lodash";

const demoDataBaseURL = "https://freshclinics-insights.s3.ap-southeast-2.amazonaws.com/demo/data/"

const demoAxios = axios.create({
  baseURL: demoDataBaseURL,
  timeout: 5000
})

const dynamicAxios = (isDemo = false) => {
  return isDemo ? demoAxios : mAxios
}

export default {
  async fetchAnalyticsFilters({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/filters", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const result = await request;

    commit('MUTATE_FILTERS', result)
  },
  async fetchAnalyticsFiltersSearch({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/filters-search", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const result = await request;

    commit('MUTATE_FILTERS', result)
  },
  async fetchAnalyticsFiltersSearchClinic({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/filter-clinic", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const result = await request;

    commit('MUTATE_FILTERS', result)
  },
  async fetchAnalyticsFiltersSearchClinicNurse({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/filter-clinic-nurse", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const result = await request;

    commit('MUTATE_FILTERS', result)
  },
  async fetchFilterByState({ commit }, data) {
    const request = new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get('/api/v1/analytics/filter-by-state', { params: { ...data.params } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });

    const response = await request;
    let result = {
      from: 'collection'
    }

    if (data.type === 'both') {
      result = {
        ...result,
        postCode: sortBy(uniqBy(response.data.data[0].docs, 'postCode'), ['postCode']),
        suburb: sortBy(uniqBy(response.data.data[0].docs, 'suburb'), ['suburb'])
      }
    } else if (data.type === 'search') {
      result = {
        ...result,
        suburb: sortBy(uniqBy(response.data.data[0].docs, 'suburb'), ['suburb'])
      }

      if (data.params.postCode) {
        result = {
          ...result,
          postCode: sortBy(uniqBy(response.data.data[0].docs, 'postCode'), ['postCode']),
        }
      }
    } else {
      result = {
        ...result,
        suburb: response.data.data[0].docs
      }
    }

    commit('MUTATE_FILTERS', {
      data: result
    })
  },
  fetchClinicState({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/filter-clinic", { params: data })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentAreas({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentAreasAge({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-age", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentAreasArea({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-area", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentAreasAgeGender({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-age-gender", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentAreasByBrand({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-by-brand", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTreatmentsPerWeek({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-per-week", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchBrands({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/brands", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchBrandsAverageByArea({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/brands-average-by-area", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchSingleVsMultipleTreatment({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/treatment-areas-single-multiple", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchActiveInactiveClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/active-inactive-clinics", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchActiveNurses({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/active-nurses", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchLastAppointments({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/last-appointments", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAppointmentNotes({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/appointments-with-without-notes", {
          params: { ...data },
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPatientTrend({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get(`/api/v1/analytics/patient-trends-${data.type}`, { params: { ...data.params } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchOvertime({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/overtime", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceCurrentNumberOfPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-current-num-patients", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceCurrentNumberOfAppointments({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-current-num-appointments", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceCurrentNumberOfProductPurchases({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-product-purchases-in-last-30-days", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceTreatmentVolume({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-volume-of-treatments-by-treatment-area-by-nurse", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformancePatientCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-new-vs-returning-patient-count", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceProductPurchasesData({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-annual-product-purchases", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceTopTreatmentAreas({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-top-5-treatment-areas", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceTreatmentTypeCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-treatment-type-count", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPerformanceSpidergram({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-performance-spidergram", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  // Clinic Performance Dashboard V2 routes
  fetchClinicCurrentNumberPatients({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-number-of-appointments"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `number-of-appointments.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicCurrentNumberAppointments({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-number-of-appointments-vs-top-performers"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `number-of-appointments-per-month.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicMixPatientsCount({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-mix-patients-count"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `mix-patients.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductPurchasesLast30Days({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-dashboard-v2-product-purchases-in-last-30-days", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicVolumeTreatmentsByTreatmentAreaByNurse({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-dashboard-v2-volume-of-treatments-by-treatment-area-by-nurse", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicAnnualProductPurchases({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-dashboard-v2-annual-product-purchases", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicTop5TreatmentAreas({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-dashboard-v2-top-5-treatment-areas", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicTreatmentTypeCount({ commit }, data) {
    return new Promise((resolve, reject) => {
      dynamicAxios(false)
        .get("/api/v1/analytics/clinic-dashboard-v2-treatment-mix-with-top-performers", { params: { ...data } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicTreatmentMix({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-treatment-mix"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `treatment-mix-your-clinic.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicInjectablesSpend({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-injectables-spend"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `injectables-spend.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicNumberOfClientsNotBooked({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-number-of-clients-not-booked"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `number-of-patients-not-booked.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicAppointmentVolumes({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-appointment-volume"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `appointment-volume.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductVolumes({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-product-volume"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `product-volume.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductMix({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-product-mix-by-spend"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `product-mix-by-spend.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPatientCount({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-number-of-patients"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `number-of-patients.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicPatientDemographics({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-patient-demographics"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `patient-demographics.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicAvgAppointmentsPerDay({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-average-appointments-per-day-vs-top-performers"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `avg-appointments-per-day.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicTimeBetweenAppointments({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-time-between-appointments"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `time-between-appointments-${data['treatment_type']}.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicAvgTreatmentsPerAppointment({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-average-treatments-per-appointment"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `avg-treatments-per-appointment-${data['treatment_type']}.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductPurchases({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-product-purchases"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `product-purchases.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductSpendBreakdowns({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-product-spend-breakdown"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `product-spend-breakdown.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductDosageTrends({ commit }, data) {

    let url = "/api/v1/analytics/clinic-dashboard-v2-product-dosage-trends"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `product-dosage-trends-${data['product'][0]}.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicProductFilter({ commit }, data) {
    
    let url = "/api/v1/analytics/clinic-dashboard-v2-filter-products"
    let params = { ...data }

    const isDemo = data['demo']
    
    if (isDemo){
      url = `filter-products.json`
      params = {}
    }

    return new Promise((resolve, reject) => {
      dynamicAxios(isDemo)
        .get(url, { params: params })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        })
    });
  },
};
