/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
import {
  ACTION_UPDATE_POPUP,
  ACTION_UPDATE_POPUP_FLAG
} from "../types/auth-type.js";
Vue.use(VueCookies);

export default {
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    };

    // If remember_me is enabled change firebase Persistence
    if (!payload.checkbox_remember_me) {
      // Change firebase Persistence
      firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION)

        // If success try to login
        .then(function() {
          dispatch("login", newPayload);
        })

        // If error notify
        .catch(function(err) {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    } else {
      // Try to login
      dispatch("login", newPayload);
    }
  },
  login({ commit, state, dispatch }, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });

      return false;
    }

    // Try to sigin
    firebase
      .auth()
      .signInWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )

      .then(
        result => {
          // Set FLAG username update required for updating username
          let isUsernameUpdateRequired = false;

          // if username is provided and updateUsername FLAG is true
          // set local username update FLAG to true
          // try to update username
          if (payload.updateUsername && payload.userDetails.displayName) {
            isUsernameUpdateRequired = true;

            dispatch("updateUsername", {
              user: result.user,
              username: payload.userDetails.displayName,
              notify: payload.notify,
              isReloadRequired: true
            });
          }

          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          // if username update is not required
          // just reload the page to get fresh data
          // set new user data in localstorage
          if (!isUsernameUpdateRequired) {
            router.push(router.currentRoute.query.to || "/");
            commit("UPDATE_USER_INFO", result.user.providerData[0], {
              root: true
            });
          }
        },
        err => {
          // Close animation if passed as payload
          if (payload.closeAnimation) payload.closeAnimation();

          payload.notify({
            time: 2500,
            title: "Error",
            text: err.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      );
  },

  // Google Login
  loginWithGoogle({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Facebook Login
  loginWithFacebook({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Twitter Login
  loginWithTwitter({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning"
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        router.push(router.currentRoute.query.to || "/");
        commit("UPDATE_USER_INFO", result.user.providerData[0], { root: true });
      })
      .catch(err => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },
  registerUser({ dispatch }, payload) {
    // create user using firebase
    firebase
      .auth()
      .createUserWithEmailAndPassword(
        payload.userDetails.email,
        payload.userDetails.password
      )
      .then(
        () => {
          payload.notify({
            title: "Account Created",
            text: "You are successfully registered!",
            iconPack: "feather",
            icon: "icon-check",
            color: "success"
          });

          const newPayload = {
            userDetails: payload.userDetails,
            notify: payload.notify,
            updateUsername: true
          };
          dispatch("login", newPayload);
        },
        error => {
          payload.notify({
            title: "Error",
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
      );
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.displayName
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.displayName;
        commit("UPDATE_USER_INFO", newUserData, { root: true });

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          router.push(router.currentRoute.query.to || "/");
        }
      })
      .catch(err => {
        payload.notify({
          time: 8800,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      });
  },

  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(
          payload.userDetails.email,
          payload.userDetails.password,
          payload.rememberMe,
          payload.userId ? payload.userId : null,
          payload.secret ? payload.secret : null
        )
        .then(response => {

          // If there's user data in response
          if (payload.secret && payload.userId) {
            localStorage.setItem("userId", payload.userId);
          }

          if(payload.iframe_view){
            localStorage.setItem("iframe-view", JSON.stringify(payload.iframe_view) );
          }

          if (response.data) {
            // router.push(router.currentRoute.query.to || '/')
            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
              response.data.data.user.firstName
            );
            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
              JSON.stringify(response.data.data.user)
            );
            if(payload.orderId){
              localStorage.setItem("orderId", payload.orderId);
            }
            let organization = response.data.data.user.organizations[0]
            if (response.data.data.user.organizations.length > 0) {
              if(payload.iframe_view && payload.organizationId){
                organization = response.data.data.user.organizations.find(
                  org => org._id === payload.organizationId
                  );
                sessionStorage.setItem("doctorClinicSelectedClinic", payload.clinicId);
              }
                localStorage.setItem(
                  `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`,
                  organization.role
                );
                localStorage.setItem(
                  `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`,
                  organization._id
                );
            }
            // localStorage.setItem('permissions', JSON.stringify(response.data.data.permit));
            // localStorage.setItem('token', JSON.stringify(user.data.token));
            // localStorage.setItem('AccessToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,user.data.token.accessToken);
            // localStorage.setItem('refreshToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,response.data.token.refreshToken);

            // Set accessToken and refresh token

            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`,
              response.data.data.token.accessToken
            );

            Vue.$cookies.set(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
              response.data.data.token.refreshToken,
              response.data.data.token.refreshTokenExpiresIn,
              "",
              window.location.host,
              true
            );

            /*  localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RefreshToken`,
              response.data.data.token.refreshToken
            ); */
            // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}TokenExpiry`, date);
            localStorage.setItem(
              `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`,
              "true"
            );

            //set cookie if keep me login
            if (payload.rememberMe === true) {
              Vue.$cookies.set(
                `${process.env.VUE_APP_ACCESS_TOKEN_NAME}HasCredRemembered`,
                true,
                response.data.data.token.refreshTokenExpiresIn,
                "",
                window.location.host,
                true
              );
            }
            
            // Update user details
            commit("UPDATE_USER_INFO", response.data.data.user, { root: true });
            // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})
            // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.token.accessToken);

            if (
              response.data.data.user.organizations.length > 0 &&
              organization.role.toUpperCase() !==
                "Staff".toUpperCase()
            ) {
              if (
                organization.role.toUpperCase() ===
                "superAdmin".toUpperCase()
              ) {
                router.push("/super-admin/dashboard");
              }
              if (
                organization.role.toUpperCase() ===
                  "franchise".toUpperCase() ||
                organization.role ===
                  "subFranchise".toUpperCase()
              ) {
                router.push("/franchise/notice-board");
              }
              if (
                organization.role.toUpperCase() ===
                  "clinic".toUpperCase() ||
                organization.role ===
                  "subClinic".toUpperCase()
              ) {
                router.push("/clinic/notice-board");
              }

              if (
                organization.role.toUpperCase() ===
                "doctorClinic".toUpperCase()
              ) {
                router.push("/doctor-clinic/shop");
              }
              if (
                organization.role.toUpperCase() ===
                "org owner".toUpperCase()
              ) {
                if(payload.iframe_view){
                  if(payload.orderId){
                    router.push('/org-owner/checkout')
                  }
                  else if(payload.productId){
                    router.push(`/org-owner/product/${payload.productId}`)
                  }
                  else if(payload.target === 'compliance'){
                    router.push('/org-owner/compliance')
                  }
                  else if(payload.target === 'resource-hub'){
                    router.push('/org-owner/resources')
                  }
                  else {
                    router.push('/org-owner/shop')
                  }
                }
                else{
                  // if there is sharedproductId from shared product url redirect to product detail page
                  // else redirect to default homepage
                  if(router.currentRoute.query.sharedProductId){
                    router.push("/org-owner/product/" + router.currentRoute.query.sharedProductId);
                  }
                  else {
                    router.push("/org-owner/home");
                  }
                }
              }
              if (
                organization.role.toUpperCase() ===
                "dermaSpecialist".toUpperCase()
              ) {
                router.push("/derma-specialist/shop");
              }

              if (
                organization.role.toUpperCase() ===
                "doctor".toUpperCase()
              ) {
                router.push("/doctor/notice-board");
              }

              if (organization.role.toUpperCase() ===
                "supplier".toUpperCase()
              ) {
                router.push("/supplier/insights");
              }

              if (
                organization.role.toUpperCase() ===
                "nurse".toUpperCase()
              ) {
                if(payload.iframe_view){
                  if(payload.orderId){
                    router.push('/nurse/checkout')
                  }
                  else if(payload.productId){
                    router.push(`/nurse/product/${payload.productId}`)
                  }
                  else {
                    router.push('/nurse/shop')
                  }
                }
                else{
                  router.push("/nurse/home");
                }
              }
              if (
                organization.role.toUpperCase() ===
                "admin".toUpperCase()
              ) {
                router.push("/admin/dashboard");
              }
            } else {
              if (response.data.data.user.userType === "superAdmin") {
                // Navigate User to homepage
                // if there is sharedproductId from shared product url redirect to product detail page
                if(router.currentRoute.query.sharedProductId){
                  router.push("/super-admin/store/product/" + router.currentRoute.query.sharedProductId);
                }
                else {
                  router.push("/super-admin/dashboard");
                }
              }
              if (
                (response.data.data.user.userType === "franchise" &&
                  response.data.data.user.isProfileCompleted) ||
                response.data.data.user.userType === "subFranchise"
              ) {
                router.push("/franchise/dashboard");
              }
              if (
                (response.data.data.user.userType === "clinic" &&
                  response.data.data.user.isProfileCompleted) ||
                response.data.data.user.userType === "subClinic"
              ) {
                if (
                  response.data.data.user.userType === "clinic" &&
                  (!response.data.data.user.financialDetails ||
                    !response.data.data.user.financialDetails.accountNumber)
                ) {
                  router.push("/clinic/setup-bank-detail");
                } else {
                  router.push("/clinic/dashboard");
                }
              }

              if (response.data.data.user.userType === "clinic") {
                router.push("/clinic/notice-board");
                // GIDEON: This would be removed now
                // if (response.data.data.user.isProfileCompleted)
                //   router.push("/clinic/dashboard");
                // else {
                //   router.push("/clinic-complete-profile");
                // }
              }

              if (response.data.data.user.userType === "subClinic")
                router.push("/clinic/notice-board");

              if (
                response.data.data.user.userType === "franchise" ||
                response.data.data.user.userType === "subFranchise"
              ) {
                router.push("/franchise/notice-board");
              }

              if (response.data.data.user.userType === "doctor") {
                
                // This is to redirect doctors to the US site
                if ( process.env.VUE_APP_REGION === "US") {

                  // Let the commit settle in to update the user info
                  setTimeout(()=>{
                    window.location = process.env.VUE_APP_DOCTOR_PORTAL;
                  },0);

                  resolve(response);
                  return;
                }

                if(payload.iframe_view){

                  if(payload.orderId){
                    router.push('/doctor/checkout')
                  }
                  else if(payload.productId){
                    router.push(`/doctor/product/${payload.productId}`)
                  }
                  else if(payload.target === 'resource-hub'){
                    router.push('/doctor/resources')
                  }
                  else {
                    router.push('/doctor/shop')
                  }
                }
                else{
                  
                  if(router.currentRoute.query.sharedProductId){
                    router.push("/doctor/product/" + router.currentRoute.query.sharedProductId);
                  }else {
                    router.push("/doctor/notice-board");
                  }
                  
                }

              }

              if (response.data.data.user.userType === "nurse") {
                if(payload.iframe_view){
                  if(payload.orderId){
                    router.push('/nurse/checkout')
                  }
                  else if(payload.productId){
                    router.push(`/nurse/product/${payload.productId}`)
                  }
                  else if(payload.target === 'resource-hub'){
                    router.push('/nurse/resources')
                  }
                  else {
                    router.push('/nurse/shop')
                  }
                }
                else{
                  // Navigate User to homepage
                  // if there is sharedproductId from shared product url redirect to product detail page
                  if(router.currentRoute.query.sharedProductId){
                    router.push("/nurse/product/" + router.currentRoute.query.sharedProductId);
                  }
                  else {
                    router.push("/nurse/home");
                  }
                }
              }
              if (response.data.data.user.userType === "admin") {
                router.push("/admin/dashboard");
              }
              if (response.data.data.user.userType === "basic") {
                if(payload.iframe_view){
                  if(payload.target === 'resource-hub'){
                    router.push('/basic/resources')
                  }
                }
                else{
                  // Navigate User to homepage
                  // if there is sharedproductId from shared product url redirect to product detail page
                  if(router.currentRoute.query.sharedProductId){
                    router.push("/basic/product/" + router.currentRoute.query.sharedProductId);
                  }
                  else {
                    router.push("/basic/notice-board");
                  }
                }
              }
              if (response.data.data.user.userType === "dermaSpecialist") {
                router.push("/derma-specialist/shop");
              }
              if (response.data.data.user.userType === "doctorClinic") {
                router.push("/doctor-clinic/shop");
              }

              // if (
              //   response.data.data.user.userType === "clinic" &&
              //   !response.data.data.user.isProfileCompleted
              // ) {
              //   router.push("/clinic-complete-profile");
              // }

              // if (
              //   response.data.data.user.userType === "franchise" &&
              //   !response.data.data.user.isProfileCompleted
              // ) {
              //   router.push("/franchise-complete-profile");
              // }
            }

            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(response => {
          if (
            response &&
            response.message &&
            response.message === "Request failed with status code 401"
          )
            reject({
              message: "Email/Password is invalid. Please try again."
            });
          else if (!response.data) {
            reject({
              message: response
            });
          } else reject({ message: response.data.message });
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    const { userType } = payload.userDetails;

    return new Promise((resolve, reject) => {
      // Check confirm password
      if (userType !== "doctorClinic") {
        const { password, confirmPassword } = payload.userDetails;
        if (password !== confirmPassword) {
          reject({ message: "Password doesn't match. Please try again." });
        }
      }

      if (userType === "franchise" || userType === "doctorClinic") {
        jwt
          .registerFranchise(payload.userDetails)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch(error => {
            // console.log(error.response)
            // console.log(response.data)
            reject(error);
          });
      } else {
        const {
          displayName,
          email,
          password,
          confirmPassword,
          contactNumber,
          userType
        } = payload.userDetails;

        jwt
          .registerUser(displayName, email, password, userType)
          .then(response => {
            // Redirect User
            router.push(router.currentRoute.query.to || "/");

            // Update data in localStorage
            localStorage.setItem("accessToken", response.data.accessToken);
            commit("UPDATE_USER_INFO", response.data.userData, { root: true });

            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  registerDoctorClinic({ commit }, payload) {
    jwt
      .registerDoctorClinic(payload.userDetails)
      .then(response => {
        // Redirect User
        router.push(router.currentRoute.query.to || "/");

        // Update data in localStorage
        localStorage.setItem("accessToken", response.data.accessToken);
        commit("UPDATE_USER_INFO", response.data.userData, { root: true });

        resolve(response);
      })
      .catch(error => {
        // console.log(error.response)
        // console.log(response.data)
        reject(error);
      });
  },
  fetchAccessToken() {
    return new Promise(resolve => {
      jwt.refreshToken().then(response => {
        resolve(response);
      });
    });
  },
  resetPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .resetPassword(payload.userDetails.password, payload.userDetails.token)
        .then(response => {
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(response => {
          reject({ message: response.data.message });
        });
    });
  },
  forgotPwd({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .forgotPasswordAdmin(payload.userDetail.email)
        .then(response => {
          if (response.data) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(response => {
          reject({ message: response.data.message });
        });
    });
  },
  changeUserPassword({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .changePassword(
          payload.userDetails.currentPassword,
          payload.userDetails.newPassword
        )
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(err => {
          reject({ message: err.data.message });
        });
    });
  },

  checkTokenExpiry({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .checkTokenExpiry(payload.token)
        .then(response => {
          // If there's user data in response
          if (response.status == httpStatusCode.OK) {
            resolve(response);
          } else {
            reject({ message: response.data.message });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.message });
        });
    });
  },

  verifyAccount({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/user/verifyAccount", payload)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.response);
        });
    });
  },

  checkLoggedIn({ commit }, payload) {
    if (payload.token) {
      if (payload.userType === "superAdmin") {
        return "/admin/dashboard";
      }
      if (payload.userType === "franchise") {
        router.push("/franchise/dashboard");
      }
      if (payload.userType === "clinic") {
        router.push("/clinic/dashboard");
      }
      if (payload.userType === "doctor") {
        router.push("/doctor/dashboard");
      }
      if (payload.userType === "nurse") {
        router.push("/nurse/dashboard");
      }
      if (payload.userType === "admin") {
        router.push("/sub-admin/dashboard");
      }
    }
  },
  [ACTION_UPDATE_POPUP]({ commit }, show) {
    commit("UPDATE_POPUP", show);
  },
  [ACTION_UPDATE_POPUP_FLAG]({ commit }, show) {
    commit("UPDATE_POPUP_FLAG", show);
  }
};
