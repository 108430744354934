import state from './moduleAIState.js'
import mutations from './moduleAIMutations.js'
import actions from './moduleAIActions.js'
import getters from './moduleAIGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
