import axios from '@/axios';

export default {
  async fetchAllFilters({ commit }) {
    try {
      const request = await axios.get("/api/v1/prismic/getFilters")
      const { data } = await request;

      commit("MUTATE_ALL_FILTERS", data.data)
    } catch (error) {
      console.log(`fetch prismic all filters: ${error}`)
    }
  },
  async searchResources({ commit }, filters) {
    try {
      const request = await axios.get("/api/v1/prismic/getResources", { params: filters })

      const { data } = await request;

      commit("MUTATE_RESOURCES_SEARCH_RESULT", {
        data: data.data,
        isLoadMore: filters.isLoadMore || false
      })

      return data.data
    } catch (error) {
      console.log(`search prismic resources: ${error}`)

      return []
    }
  },
  async fetchRecentResources({ commit }) {
    try {
      const request = await axios.get("/api/v1/prismic/getResources")

      const { data } = await request;

      commit("MUTATE_RESOURCES_RECENT", data.data)
    } catch (error) {
      console.log(`fetch prismic recent resources: ${error}`)
    }
  },
  async fetchComplianceResources({ commit }, tag) {
    try {
      const request = await axios.get(`/api/v1/prismic/getResources?tags=${tag}`)

      const { data } = await request;

      commit("MUTATE_RESOURCES_COMPLIANCE", data.data)
    } catch (error) {
      console.log(`fetch prismic compliance resources: ${error}`)
    }
  },
}
