
import state from "./moduleStoreFeedbackSurveyState.js";
import mutations from "./moduleStoreFeedbackSurveyMutations.js";
import actions from "./moduleStoreFeedbackSurveyActions.js";
import getters from "./moduleStoreFeedbackSurveyGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
