

const storeHelper = {
    install(Vue, options) {
        Vue.prototype.$replaceUrlTemplate = (url, newValue) => {
            const regex = /\[.+?\]/;
            const modifiedUrl = url.replace(regex, `${newValue}`);
            return modifiedUrl;
        };
        Vue.prototype.$getUserType = () => {
            const userType = JSON.parse(
                    localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
                ).userType;
                
                const role = localStorage.getItem(
                    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
                );
                
                // const storeState = this.$store.state;
                
                let user = "";
                if(role === "Org Owner"){
                    user = "org-owner"
                }
                else {
                    if (userType === "doctor") {
                        user = "doctor";
                    } 
                    else if(userType === "basic"){
                        user = "basic";
                    }
                    else if(userType === "superAdmin"){
                        user = "super-admin/store";
                    }
                    else {
                        user = "nurse";
                    }
                }
              
                // superAdmin role checker
                // if (
                //     storeState.AppActiveUser &&
                //     storeState.AppActiveUser.userRole === "superAdmin"
                // ) {
                //     user = "super-admin/store"
                // }
                return user
        };
    },
  };
  
  export default storeHelper;
  