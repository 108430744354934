/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  fetchPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/patients", { params: data })
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  patientDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/patientDetail/" + data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  patientTreatmentList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/patients/treatmentHistory", {
          params: data
        })
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchNursePatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/nurses/patients/webPortal", { params: data })
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  fetchFranchisePatient({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/franchises/patient-list?page=" +
          data.page +
          "&limit=" +
          data.limit +
          "&search=" +
          data.search +
          "&sort=" +
          data.sort +
          "&dir=" +
          data.dir +
          "&filter=" +
          data.filter
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAllPatients({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/patientList?page=" +
          data.page +
          "&limit=" +
          data.limit +
          "&search=" +
          data.search +
          "&sort=" +
          data.sort +
          "&dir=" +
          data.dir +
          "&activeClinicId=" +
          data.activeClinicId
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  exportCsv({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/patient-export-csv")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};
