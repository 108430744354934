/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
// import {use} from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          // If there's user data in response
          if (response.data) {
            // console.log(router.currentRoute.query)
            // Navigate User to homepage
            // router.push('/admin/dashboard')
            // // router.push(router.currentRoute.query.to || '/')
            // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`, JSON.stringify(response.data.data.user));
            // localStorage.setItem('firstName', response.data.data.firstName);
            // // localStorage.setItem('permissions', JSON.stringify(response.data.data.permit));
            // // localStorage.setItem('token', JSON.stringify(user.data.token));
            // // localStorage.setItem('AccessToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,user.data.token.accessToken);
            // // localStorage.setItem('refreshToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,response.data.token.refreshToken);
            // localStorage.setItem('refreshToken', response.data.data.token.refreshToken);
            // // console.log(new Date(new Date().setTime() + 60 * 60 * 24 * 1000))
            // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            // localStorage.setItem('tokenExpiry', date)
            // localStorage.setItem('loggedIn', 'true')
            // // Set accessToken
            // localStorage.setItem("accessToken", response.data.data.token.accessToken)

            // // Update user details
            // commit('UPDATE_USER_INFO', response.data.data, {root: true})
            // // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})
            // // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})

            // // Set bearer token in axios
            // commit("SET_BEARER", response.data.data.token.accessToken)

            resolve(response);
          } else {
            reject({ message: response.data.msg });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.msg });
          // reject(error)
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .registerClinic(payload.userDetails)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || "/");
          // Update data in localStorage
          // localStorage.setItem("accessToken", response.data.accessToken)
          // commit('UPDATE_USER_INFO', response.data.userData, {root: true})
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      jwt
        .checkEmailAvailability(email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getInvitationDetail({ commit }, ref) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/auth/getInvitationDetail", { params: { ref } })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAllClinic({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/fetchAllClinic")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchUserList?page=" +
          data.page +
          "&limit=" +
          data.limit +
          "&search=" +
          data.search +
          "&sort=" +
          data.sort +
          "&dir=" +
          data.dir +
          "&userType=" +
          data.userType +
          "&activeClinicId=" +
          data.activeClinicId
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchDoctorClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchUserListByType?page=" +
          data.page +
          "&limit=" +
          data.limit +
          "&search=" +
          data.search +
          "&sort=" +
          data.sort +
          "&dir=" +
          data.dir +
          "&userType=" +
          data.userType +
          "&activeClinicId=" +
          data.activeClinicId
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchSupplierClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/fetchSupplierClinicList?page=" +
          data.page +
          "&limit=" +
          data.limit +
          "&search=" +
          data.search +
          "&sort=" +
          data.sort +
          "&dir=" +
          data.dir +
          "&detailSupplierId=" +
          data.detailSupplierId +
          "&state=" +
          data.state
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  generateAdminClinicInvoice({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v1/admins/generateAdminClinicInvoice?clinic=${param.clinicId}&year=${param.year}&month=${param.monthNumber}`
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAllClinics({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/userManagement/clinics/all")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchClinicsDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/userManagement/clinics/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateClinicDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/clinics/userManagement/clinics/" + data.id + "/edit",
          data.data
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateOpeningHours({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/clinics/clinicOpeningHours/" + data.id,
          data.data
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  resetClinicPassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/clinics/userManagement/clinics/" +
          data.id +
          "/resetPassword",
          data.data
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createClinic({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/userManagement/clinics/create", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createClinicBasic({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/userManagement/clinics/create/basic", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteClinic({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/clinics/userManagement/clinics/" + id + "/delete")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getClinicDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/dashboardData`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  pairDevice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/userManagement/clinics/pairDevice", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.data);
        });
    });
  },
  unPairDevice({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/userManagement/clinics/unPairDevice", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err.data);
        });
    });
  },
  fetchPairDevice({ commit }, clinicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/userManagement/clinics/getPairedDevice`, {
          params: clinicId
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAssociatedClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/userManagement/clinics/getAssociatedClinics`, {
          params: data
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeAssociatedClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/clinics/userManagement/clinics/removeAssociatedClinics`,
          data
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchToPairClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/userManagement/clinics/fetchClinicToPair", {
          params: data
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAllTreatmentHistory({ commit }, params) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/clinics/getAllTreatmentHistories`, params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchClinicAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/fetchClinicAdmin", { params: data })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createClinicAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/createClinicAdmin", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchClinicList({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/doctors/clinic/list", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchClinicAdminDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/fetchClinicAdminDetail/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateClinicAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/v1/clinics/updateClinicAdmin/" + data.clinicId,
          data.clinicData
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSubClinic({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/clinics/deleteClinicAdmin/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getStripePublishableKey({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/bankDetails/getPublishableKey")
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createSetupIntent({ commit }, id) {
    return new Promise((resolve, reject) => {
      let path = `/api/v1/clinics/bankDetails/createSetupIntent`;
      path = `/api/v1/clinics/bankDetails/createSetupIntent?clinicId=${id}`;
      axios
        .get(path)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMandateObject({ commit }, param) {
    return new Promise((resolve, reject) => {
      let path = `/api/v1/clinics/bankDetails/getMandateObject?intentId=${param.intentId
        }&accountHolderName=${param.accountHolderName
        }&accountHolderEmail=${encodeURIComponent(param.accountHolderEmail)}&clinicId=${param.clinicId}`;
      axios
        .get(path)
        .then(res => {
          // localStorage.setItem(
          //   `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
          //   res.data.data.firstName
          // );

          // localStorage.setItem(
          //   `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
          //   JSON.stringify(res.data.data)
          // );

          // commit("UPDATE_USER_INFO", res.data.data, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitStepDefaultPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/clinics/default-payment-method/${payload.userId}/submit`,
          payload.body
        )
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  saveClinicBankDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/bankDetails/create", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.user.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data.user)
          );

          commit("UPDATE_USER_INFO", res.data.data.user, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateClinicBankDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/bankDetails/edit", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getExistingDoctorRate({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/getExistingDoctorRate")
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  saveDoctorRate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/clinics/saveDoctorRate", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  getClinicDoctorRate({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/getClinicDoctorRate")
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  uploadClinicImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/clinics/uploadClinicImages?key=" + payload.key,
          payload.data,
          payload.config
        )
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  removeClinicImages({ commit }, imageUrl) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/removeClinicImages?imageUrl=" + imageUrl)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  resendVerificationEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/onboardBusiness/resendEmail", payload)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  checkIfFranchiseClinic({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/checkIfFranchiseClinic")
        .then(res => {
          commit("IS_FRANCHISE_CLINIC", res.data.data);
          resolve(res);
        })
        .catch(err => reject(err));
    });
  },
  fetchTreatmentAreas({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/applicableAreas", { params: dataTableParams })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeTreatmentAreaStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/api/v1/clinics/applicableAreas/changeStatus", payload)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchDefaultConsentList({ commit }, clinicId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/questionnaire-management?page=" +
          1 +
          "&limit=" +
          25 +
          "&search=&sort=createdAt&dir=asc"
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCustomConsentList({ commit }, clinicId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/custom-consent/" + clinicId)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeCustomConsentOrders({ commit }, data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/custom-consent/" + data._id, data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  transferAccount({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/transferAccount", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  addNewPaymentMethod({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .addNewPaymentMethod(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchClinicComplianceList({ commit }, data) {
    let path = "/api/v1/monthlyCompliance/" + data.clinicId + "/clinic/complianceList";
    return new Promise((resolve, reject) => {
      axios
        .get(path, {
          params: {
            page: data.page,
            limit: data.limit,
            sort: data.sort,
            search: data.search,
            year: data.year,
            month: data.month,
            clinic: data.clinic,
            status: data.status,
          }
        })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchPOSEnabledClinics({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/clinics/get-pos-enabled-clinics/${data.orgId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateScriptProductPatientDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/api/v1/clinics/updateScriptProductPatientDetails/${data.id}`, data.payload)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
};
