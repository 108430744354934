/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  sendTlcEmail({ commit }, data) {
    let path =
      "/api/v1/tlc/sendPatientTlcIframeEmail/";
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getClinicDetails({ commit }, data) {
    let path =
      "/api/v1/tlc/clinicDetails/";
    return new Promise((resolve, reject) => {
      axios
        .post(path, data.payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getTLCapplications({ commit }, orgId) {
    let path =`/api/v1/tlc?orgId=${orgId}`;
    return new Promise((resolve, reject) => {
      axios
        .get(path)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};
