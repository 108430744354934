/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios";

export default {
  onboardBusiness({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/onboard/business", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  resendVerificationEmail({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/onboard/business/resendEmail", payload)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getUserDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/onboard/userDetail/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitStepOne({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/onboard/step-one/${payload.userId}/submit`, payload.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitStepTwo({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/onboard/step-two/${payload.userId}/submit`, payload.body)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  submitStepThree({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/api/v1/onboard/step-three/${payload.userId}/submit`,
          payload.body
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getStripePublishableKey({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/onboard/bankDetails/getPublishableKey`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  createSetupIntent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/onboard/bankDetails/${payload.userId}/createSetupIntent`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMandateObject({ commit }, param) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v1/onboard/bankDetails/${param.userId}/getMandateObject?intentId=${param.intentId}&accountHolderName=${param.accountHolderName}&accountHolderEmail=${param.accountHolderEmail}`
        )
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data)
          );

          commit("UPDATE_USER_INFO", res.data.data, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  saveClinicBankDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/onboard/bankDetails/create", data)
        .then(res => {
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.user.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data.user)
          );

          commit("UPDATE_USER_INFO", res.data.data.user, { root: true });
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
