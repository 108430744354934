import jwt from "../../http/requests/auth/jwt/index.js";
import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {

  addSupplier({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpSupplier(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getSupplierList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpSupplierList(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getAllSupplierList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpAllSupplierList()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getInstantSupplierList({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpAllInstantSupplierList()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getSupplierDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpSupplierDetail(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateSupplierDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateHttpSupplierDetail(data)
        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    });
  },
  getSupplierProductList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpProductListBySupplier(data.dataTableParams, data.id)
        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    });
  },
  removeSupplierFromProduct({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpProductFromSupplier(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getSupplierOrderList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpOrderListBySupplier(data.dataTableParams, data.id)
        .then((res) => {
          resolve(res);
        }).catch(err => {
          reject(err)
        })
    });
  },
  getOrderDetails({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpOrderDetailForSupplier(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  searchNearbyClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpsearchNearbyClinics(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
};
