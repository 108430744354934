import Vue from "vue";
import markerSDK from '@marker.io/browser';

const projectId = process.env.VUE_APP_MARKER_PROJECT_ID || ''

var markerWidget = null;

if(projectId){
  markerSDK.loadWidget({
      project: projectId,
  }).then((widget) => {
      markerWidget = widget;
      if(window.location.href.indexOf("/insights/") == -1){
        markerWidget.hide();
      }
  });
}

Vue.mixin({
    methods: {
      showMarkerWidget() {
        setTimeout(() => {
            if(markerWidget) {
                markerWidget.show()
            }
        }, 2000)
      },
      hideMarkerWidget() {
        if(markerWidget) {
            markerWidget.hide()
        }
      },
    }
});