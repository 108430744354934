const regionHelper = {
  install(Vue, options) {
    Vue.prototype.$isAuRegion = () => {
      return process.env.VUE_APP_REGION === "AU";
    };
    Vue.prototype.$isUSRegion = () => {
      return process.env.VUE_APP_REGION === "US";
    };
    Vue.prototype.$isNZRegion = () => {
      return process.env.VUE_APP_REGION === "NZ";
    };
  },
};

export default regionHelper;
