/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"
import { sortBy } from "lodash";

export default {
  MUTATE_CHART_DATA(state, data) {
    state.chartData = data;
  },
  MUTATE_TABLE_HEADERS(state, headers) {
    state.table.headers = headers
  },
  MUTATE_TABLE_DATA(state, data) {
    state.table.data = data;
  },
  MUTATE_FILTERS_BY(state, { key, value }) {
    state.filters[key] = value;
  },
  MUTATE_FILTERS(state, { data }) {

    if (data.organization) {
      state.filters["org"] = [
        ...data.organization.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.clnc_state) {
      state.filters["clnc_state"] = [
        ...data.clnc_state.data.map((item) => {
          return {
            text: item.state_name,
            value: item.state,
          }
        })
      ];
    }

    if (data.clnc_postcode) {
      state.filters["clnc_postcode"] = [
        ...data.clnc_postcode.data.map((item) => {
          return {
            text: item.postcode,
            value: item.postcode,
          }
        })
      ];
    }

    if (data.clnc_suburb) {
      state.filters["clnc_suburb"] = [
        ...data.clnc_suburb.data.map((item) => {
          return {
            text: item.suburb,
            value: item.suburb,
          }
        })
      ];
    }

    if (data.ptnt_state) {
      state.filters["ptnt_state"] = [
        ...data.ptnt_state.data.map((item) => {
          return {
            text: item.state_name,
            value: item.state,
          }
        })
      ];
    }

    if (data.ptnt_postcode) {
      state.filters["ptnt_postcode"] = [
        ...data.ptnt_postcode.data.map((item) => {
          return {
            text: item.postcode,
            value: item.postcode,
          }
        })
      ];
    }

    if (data.ptnt_suburb) {
      state.filters["ptnt_suburb"] = [
        ...data.ptnt_suburb.data.map((item) => {
          return {
            text: item.suburb,
            value: item.suburb,
          }
        })
      ];
    }

    if (data.clinic_name) {
      state.filters["clinic"] = [
        ...data.clinic_name.data.map((item) => {
          return {
            text: item.clinicName,
            value: item.id,
          }
        })
      ];
    }

    if (data.product_name) {
      state.filters["product"] = [
        ...data.product_name.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.product_type) {
      state.filters["product_type"] = [
        ...data.product_type.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.treatment_area) {
      state.filters["area"] = [
        ...data.treatment_area.data.map((item) => {
          return {
            text: item.area,
            value: item.area,
          }
        })
      ];
    }

    if (data.brand) {
      state.filters["brand"] = [
        ...data.brand.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.nurse) {
      state.filters["nurse"] = [
        ...data.nurse.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.doctor) {
      state.filters["doctor"] = [
        ...data.doctor.data.map((item) => {
          return {
            text: item.name,
            value: item.id,
          }
        })
      ];
    }

    if (data.dose_ml) {
      state.filters["dose_ml"] = [
        ...data.dose_ml.data.map((item) => {
          return {
            text: item.amount,
            value: item.amount,
          }
        })
      ];
    }

    if (data.unit) {
      state.filters["unit"] = [
        ...data.unit.data.map((item) => {
          return {
            text: item.amount,
            value: item.amount,
          }
        })
      ];
    }
    // ['age', 'gender', 'clinic_quintile', 'number_of_days', 'patient_state', 'treatment_state']
    if (data.age) {
      state.filters["age"] = [
        ...data.age.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.gender) {
      state.filters["gender"] = [
        ...data.gender.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.clinic_quintile) {
      state.filters["quintile"] = [
        ...data.clinic_quintile.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.number_of_days) {
      state.filters["number_of_days"] = [
        ...data.number_of_days.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.patient_state) {
      state.filters["patient_state"] = [
        ...data.patient_state.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.treatment_state) {
      state.filters["treatment_state"] = [
        ...data.treatment_state.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

    if (data.treatment_category) {
      state.filters["treatment_category"] = [
        ...data.treatment_category.data.map((item) => {
          return {
            text: item.text,
            value: item.value,
          }
        })
      ];
    }

  }
}
