/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  /* fetchProducts({ commit }, data) {
     return new Promise((resolve, reject) => {
         jwt
         .fetchHttpProduct(data)
         .then(res => {
           return resolve(res);
         })
         .catch(err => {
           return reject(err);
         });
     });
   },*/
   getAllCategory({ commit }, data){
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/clinics/non-auth/categories", { params: data })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  addCategory({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpCategory(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getParentCategoryList({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpParentCategoryList()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getPopularCategory({commit}) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpPopularCategory()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getCategoryList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpCategoryList(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getSubCategoryList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpSubCategoryList(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getCategoryDetails({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpCategoryDetails(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getCategoryDetailsBySlug({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpCategoryDetailsBySlug(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  updateCategoryDetails({commit},data){
    return new Promise((resolve,reject)=>{
      jwt
        .updateHttpCategoryDetail(data)
        .then((res) => {
          resolve(res);
        }).catch(err =>{
        reject(err)
      })
    });
  },
  getCategoryProductList({commit},data){
    return new Promise((resolve,reject)=>{
      jwt
        .fetchHttpProductListByCategory(data.dataTableParams, data.id)
        .then((res) => {
          resolve(res);
        }).catch(err =>{
        reject(err)
      })
    });
  },
  deleteCategoryById({commit},id){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpCategory(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  removeProductFromCategory({commit},data){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpProductFromCategory(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  updateMenuParentCategories( {commit}, categories ){
    commit('SET_PARENT_CATEGORIES', categories)
  }

  /*fetchProductDetail({commit},id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpProductDetail(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },*/
  
};
