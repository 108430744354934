import moment from 'moment'

export default {
  insertBody(elx) {
    document.body.insertBefore(elx, document.body.firstChild);
  },
  removeBody(element) {
    let bodyx = document.body;
    bodyx.removeChild(element);
  },
  changePosition(elx, content, conditional) {
    let topx = 0;
    let leftx = 0;
    let widthx = 0;
    let scrollTopx = window.pageYOffset || document.documentElement.scrollTop;
    if (elx.getBoundingClientRect().top + 300 >= window.innerHeight) {
      setTimeout(() => {
        if (conditional) {
          topx =
            elx.getBoundingClientRect().top - content.clientHeight + scrollTopx;
        } else {
          topx =
            elx.getBoundingClientRect().top -
            content.clientHeight +
            elx.clientHeight +
            scrollTopx;
        }
      }, 1);
    } else {
      topx = conditional
        ? elx.getBoundingClientRect().top + elx.clientHeight + scrollTopx + 5
        : elx.getBoundingClientRect().top + scrollTopx;
    }

    leftx = elx.getBoundingClientRect().left;
    widthx = elx.offsetWidth;

    let cords = {
      left: `${leftx}px`,
      top: `${topx}px`,
      width: `${widthx}px`
    };

    return cords;
  },
  exportToCSV(csv, fileName) {
    let blob = new Blob([csv], {
      type: 'application/csv;charset=utf-8;'
    });

    if (window.navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, fileName + '.csv');
    }
    else {
      let link = document.createElement("a");
      if (link.download !== undefined) {
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', fileName + '.csv');
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else {
        csv = 'data:text/csv;charset=utf-8,' + csv;
        window.open(encodeURI(csv));
      }
    }
  },
  formattedDate(selectedDate){
    return moment(selectedDate).format("YYYY-MM-DD")
  },
  startDate(dt){
    const date = new Date()
    
    if(dt === 'year'){
      date.setFullYear(date.getFullYear() - 1)
      return date
    }
    date.setMonth(date.getMonth() - 1)
    return date
    
  },
};
