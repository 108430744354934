/*=========================================================================================
  File Name: moduleTreatmentTypesActions.js
  Description: Treatment Types Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  treatmentTypes({commit}, data){
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/treatmentTypes", { params: data })
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  treatmentTypesById({commit}, payload){
    return new Promise((resolve, reject) => {
      axios.get("/api/v1/treatmentTypes/"+payload.id)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  saveTreatmentTiming({commit}, payload){
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/treatmentTypes/"+payload.id+"/save", {data: payload.data})
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
