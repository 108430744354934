/*=========================================================================================
  File Name: moduleTreatmentTypes.js
  Description: Treatment Types Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import state from './moduleTreatmentTypesState.js'
import mutations from './moduleTreatmentTypesMutations.js'
import actions from './moduleTreatmentTypesActions.js'
import getters from './moduleTreatmentTypesGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
