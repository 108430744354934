import { groupBy, sortBy } from "lodash"

export default {
  MUTATE_ADD_CONVO_ITEM(state, data) {
    state.convoItems.push(data)
  },
  MUTATE_CONVO_ITEMS(state, data) {
    state.convoItems = data
  },
  MUTATE_CLEAR_CONVO_ITEMS(state) {
    state.convoItems = []
  },
  MUTATE_CURRENT_CONVO_RESPONSE(state, data) {
    if(state.convoItems.length) {
      
        // determine how to display the result set
        let hasString = false;
        let hasNumber = false;
        if(data.resultSet && data.resultSet.length) {
          const firstRowData = data.resultSet[0]
          for (const key in firstRowData) {
            
              if (typeof firstRowData[key] === 'string') {
                  hasString = true;
              } else if (typeof firstRowData[key] === 'number') {
                  hasNumber = true;
              }
          }
          
          if(hasString && hasNumber) {
            // display chart
            data.isChartType = true
          } else {
            // display table
            data.isChartType = false
          }
        }
        state.convoItems[state.convoItems.length - 1].response = data
    }
  },
  MUTATE_CURRENT_SESSION_ID(state, sessionId) {
    state.currentSessionId = sessionId
  },
  MUTATE_GENERATE_NEW_SESSION_ID(state) {
    state.currentSessionId = state.sessionIdDefault
  },
  MUTATE_SESSION_HISTORY(state, data) {
    let listOfObjects = data
    listOfObjects = sortBy(listOfObjects, 'timestamp').reverse();

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const lastSevenDays = new Date(today);
    lastSevenDays.setDate(lastSevenDays.getDate() - 7);
    const lastThirtyDays = new Date(today);
    lastThirtyDays.setDate(lastThirtyDays.getDate() - 30);
    
    const structuredObjects = groupBy(listOfObjects, (item) => {
      const itemDate = new Date(item.timestamp);
      if (itemDate.toDateString() === today.toDateString()) {
        return "Today";
      } else if (itemDate.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
      } else if (itemDate > lastSevenDays) {
        return "Previous 7 days";
      } else if (itemDate > lastThirtyDays) {
        return "Previous 30 days";
      } else {
        return `${itemDate.toLocaleString('default', { month: 'long' })}`;
      }
    });
    state.sessionHistory = structuredObjects
  },
  MUTATE_CLEAR_SESSION_HISTORY(state) {
    state.sessionHistory = {}
  },
  MUTATE_IS_FETCHING_SESSION_HISTORY(state, flag) {
    state.isFetchingSessionHistory = flag 
  }
}
