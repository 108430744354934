/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
    SET_BEARER(state, accessToken) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken
    },
    SET_SELECTED_ORGANIZATION(state, organization) {
        state.selectedOrganization = organization
    },
    SET_SELECTED_ORGANIZATION_PAYMENT_SETUP(state, status) {
        state.selectedOrganizationPaymentSetup = status
    },
    SET_SELECTED_ORGANIZATION_ID(state, orgId) {
        state.selectedOrganizationId = orgId
    },
    SET_SELECTED_BILLING_EMAIL(state, billingEmail) {
        state.selectedOrganizationBillingEmail = billingEmail
    }
}
