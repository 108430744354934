/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  /* fetchProducts({ commit }, data) {
     return new Promise((resolve, reject) => {
         jwt
         .fetchHttpProduct(data)
         .then(res => {
           return resolve(res);
         })
         .catch(err => {
           return reject(err);
         });
     });
   },*/
  addDiscount({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .saveHttpDiscount(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getDiscountDetails({commit}, id){
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpDiscount(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getDiscountList({commit}, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpDiscountList(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchDiscountList({commit},) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchAllHttpDiscount()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateDiscountDetails({commit},data){
    return new Promise((resolve,reject)=>{
      jwt
        .updateHttpDiscountDetail(data)
        .then((res) => {
          resolve(res);
        }).catch(err =>{
        reject(err)
      })
    });
  },
  deleteDiscountById({commit},id){
    return new Promise((resolve, reject) => {
      jwt
        .deleteHttpDiscount(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  /*fetchProductDetail({commit},id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpProductDetail(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },*/
};
