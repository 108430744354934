/*=========================================================================================
  File Name: moduleEmail.js
  Description: Email Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from "./moduleStoreAlertNotificationState.js";
import mutations from "./moduleStoreAlertNotificationMutations.js";
import actions from "./moduleStoreAlertNotificationActions.js";
import getters from "./moduleStoreAlertNotificationGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
