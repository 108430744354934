<script>
import axios from 'axios';
export default {
  data() {
    return {
      localVersion: '{{UPDATE_VERSION_POST_BUILD}}',
      serverVersion: null,
    };
  },
  methods: {
    async checkVersion(url) {
      try {
        const response = await axios.get(url);
        this.serverVersion = response.data.data.version;
        if (this.serverVersion !== this.localVersion) {
          this.reloadPage();
        }
      } catch (error) {
        console.error('Error checking version:', error);
      }
    },
    reloadPage() {
      if (process.env.VUE_APP_ENV !== 'production') {
        setTimeout(() => {
          console.log(`Reloading: ${this.serverVersion},${this.localVersion}`)
        }, 10000);
      }
      // clear browser cache
      caches.keys().then((names) => {
        for (let n of names) {
          caches.delete(n);
        }
      });
      // reload page
      window.location.reload(true);
    },
  },
  created() {
    //this.checkVersion(`${process.env.VUE_APP_API_URL}/api/v1/version/client`);

    //window.addEventListener("focus", () => {
    //  this.checkVersion(`${process.env.VUE_APP_API_URL}/api/v1/version/client`);
    //});
  },
};
</script>
