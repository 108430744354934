import state from "./moduleLogState.js";
import mutations from "./moduleLogMutations.js";
import actions from "./moduleLogActions.js";
import getters from "./moduleLogGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
