/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";

import firebase from "firebase/app";
import "firebase/auth";
import router from "@/router";
import httpStatusCode from "http-status-codes";
import { use } from "vee-validate/dist/vee-validate.minimal.esm";
import axios from "@/axios";

export default {
  // JWT
  loginJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .login(payload.userDetails.email, payload.userDetails.password)
        .then(response => {
          // If there's user data in response
          if (response.data) {
            // // console.log(router.currentRoute.query)
            // // Navigate User to homepage
            // router.push('/admin/dashboard')
            // // router.push(router.currentRoute.query.to || '/')
            // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`, JSON.stringify(response.data.data.user));
            // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}Firstname`, response.data.data.firstName);
            // // localStorage.setItem('permissions', JSON.stringify(response.data.data.permit));
            // // localStorage.setItem('token', JSON.stringify(user.data.token));
            // // localStorage.setItem('AccessToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,user.data.token.accessToken);
            // // localStorage.setItem('refreshToken'+ process.env.VUE_APP_ACCESS_TOKEN_NAME,response.data.token.refreshToken);
            // localStorage.setItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`, response.data.data.token.refreshToken);
            // // console.log(new Date(new Date().setTime() + 60 * 60 * 24 * 1000))
            // var date = new Date().getTime() + 60 * 60 * 24 * 1000;
            // localStorage.setItem('tokenExpiry', date)
            // localStorage.setItem('loggedIn', 'true')
            // // Set accessToken
            // localStorage.setItem("accessToken", response.data.data.token.accessToken)

            // // Update user details
            // commit('UPDATE_USER_INFO', response.data.data, {root: true})
            // // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})
            // // commit('UPDATE_USER_INFO', JSON.stringify(response.data), {root: true})

            // // Set bearer token in axios
            // commit("SET_BEARER", response.data.data.token.accessToken)

            resolve(response);
          } else {
            reject({ message: response.data.msg });
          }
        })
        .catch(({ response }) => {
          reject({ message: response.data.msg });
          // reject(error)
        });
    });
  },
  registerUserJWT({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .registerFranchise(payload.userDetails)
        .then(response => {
          // Redirect User
          router.push(router.currentRoute.query.to || "/");
          // Update data in localStorage
          // localStorage.setItem("accessToken", response.data.accessToken)
          // commit('UPDATE_USER_INFO', response.data.userData, {root: true})
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  checkEmail({ commit }, email) {
    return new Promise((resolve, reject) => {
      jwt
        .checkEmailAvailability(email)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  registerFranchiseNurse({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/nurses/nurse/register`, payload.userDetails)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getFranchiseDashboardData({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/api/v1/franchises/dashboardData`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchFranchise({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/admins/franchiseList?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir +
            "&filter=" +
            data.filter +
            "&activeClinicId=" +
            data.activeClinicId
        )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createFranchise({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/admins/franchise/create`, data)
        .then(response => {
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFranchiseDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/admins/franchise/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateFranchiseDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/admins/franchise/" + data.id + "/edit", data.data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  resetFranchisePassword({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "/api/v1/admins/franchise/" + data.id + "/resetPassword",
          data.data
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteFranchise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/admins/franchise/" + id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  inviteUser({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/franchises/sendUserInvitation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  fetchClinics({ commit }, type) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/fetchClinics", { params: type })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  assignClinics({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/franchises/assignClinics", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchAssignClinics({ commit }, nurseId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/fetchAssignClinicsList", { params: nurseId })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendRequest({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/franchises/sendRequestForAssociation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  /* Franchise Admin Management */
  createFranchiseAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/franchises/createFranchiseAdmin", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFranchiseAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/fetchFranchiseAdmin", { params: data })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchFranchiseAdminDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/fetchFranchiseAdminDetail/" + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateFranchiseAdmin({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          "/api/v1/franchises/updateFranchiseAdmin/" + data.franchiseId,
          data.franchiseData
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteSubFranchise({ commit }, id) {
    return new Promise((resolve, reject) => {
      axios
        .delete("/api/v1/franchises/deleteFranchiseAdmin/" + id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getExistingDoctorRate({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/getExistingDoctorRate")
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  saveDoctorRate({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/franchises/saveDoctorRate", data)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchTreatmentAreas({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/applicableAreas", { params: dataTableParams })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeTreatmentAreaStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/api/v1/franchises/applicableAreas/changeStatus", payload)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  fetchProductsList({ commit }, dataTableParams) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/franchises/productsList", { params: dataTableParams })
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  },
  changeProductStatus({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch("/api/v1/franchises/productsList/changeStatus", payload)
        .then(res => resolve(res))
        .catch(err => reject(err));
    });
  }
};
