/*=========================================================================================
  File Name: moduleEcommerceGetters.js
  Description: Ecommerce Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
  isInCart: state => itemId => {
    return state.cartItems.some((item) => item._id == itemId)
  },
  totalItemQuantityIncart: state => itemId => {
    const result = state.cartItems.find((item) => item.productStoreId == itemId)
    return typeof result !== 'undefined' ? result.quantity : 0
  },
  isInWishList: state => itemId => {
    return state.wishList.some((item) => item._id == itemId)
  },
  getCartItem: state => itemId => {
    const result = state.cartItems.filter((item) => item._id == itemId)
    return result.length ? result.pop() : []
  },
  totalCarPrice: state => {
    let total = 0;
    state.cartItems.forEach(function (value, index) {
      total += value.price * value.quantity;
    })
    return total;
  },
  subTotalCartPrice: state => {
    let total = 0;
    state.cartItems.forEach(function (value, index) {
      if (value.retoolTotalDiscount) {
        total += (value.price * value.quantity) - value.retoolTotalDiscount;
      } else {
        total += value.price * value.quantity;
      }
    })
    return total;
  },
  items: state => {
    return state.cartItems;
  },
  delivery: state => {
    let total = 0;
    let suppliers = [];
    let customDeliveryFee = {}
    let appliedCustomDeliveryFee = {}

    // group custom delivery fee by supplier
    state.cartItems.map(i => {
      if (i.isCustomDeliveryFee) {
        if (customDeliveryFee[i.inventory.managedBy]) {
          customDeliveryFee[i.inventory.managedBy].push({
            deliveryFee: i.customDeliveryFee,
            productId: i.productStoreId
          })
        } else {
          customDeliveryFee[i.inventory.managedBy] = [{
            deliveryFee: i.customDeliveryFee,
            productId: i.productStoreId
          }]
        }
      }
    })

    state.cartItems.forEach(function (value, index) {
      let isExist = false;
      let maxDeliveryFee = 0;

      const sameSupplier = customDeliveryFee[value.inventory.managedBy]
      if (sameSupplier) {
        isExist = sameSupplier.find(i => i.productId === value.productStoreId)
        maxDeliveryFee = Math.max(...sameSupplier.map(i => i.deliveryFee))
      }

      if (!suppliers.includes(value.inventory.managedBy)) {
        let delivery = value.deliveryFeeTotal;
        if (isExist) {
          delivery = maxDeliveryFee
          appliedCustomDeliveryFee[value.inventory.managedBy] = maxDeliveryFee
        }
        total += delivery;
        suppliers.push(value.inventory.managedBy)
      } else {
        if (sameSupplier && !isExist && value.deliveryFeeTotal > maxDeliveryFee) {
          total -= maxDeliveryFee
          total += value.deliveryFeeTotal
          delete appliedCustomDeliveryFee[value.inventory.managedBy]
        }
      }
    })

    return {
      total,
      appliedCustomDeliveryFee
    }
  },
  getTotalGST: state => {
    let totalGST = 0;
    state.cartItems.forEach(function (value, index) {
      const total = value.price * value.quantity;
      totalGST += ((total * 0.1)/100).toFixed(2) * 100;
    });
    let suppliers = [];
    state.cartItems.forEach(function (value, index) {
      if (!suppliers.includes(value.inventory.managedBy)) {
        totalGST += ((value.deliveryFeeTotal * 0.1)/100).toFixed(2) * 100;
        suppliers.push(value.inventory.managedBy)
      }
    });
    return totalGST;
  },
  isInCartTest: (state) => (itemId) => {
    return state.cartItems.some((item) => item.productStoreId == itemId);
  },
  getCartItemTest: (state) => (itemId) => {
    const result = state.cartItems.filter(
      (item) => item.productStoreId == itemId
    );
    return result.length ? result.pop() : [];
  },
}
