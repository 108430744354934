/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import { sortBy } from "lodash";

export default {
  MUTATE_CONSENT_FORMS(state, data) {
    state.forms = data;
  },
  MUTATE_MODE(state, data) {
    state.mode = data;
  },
}
