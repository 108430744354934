import state from './moduleConsentFormsState.js'
import mutations from './moduleConsentFormsMutations.js'
import actions from './moduleConsentFormsActions.js'
import getters from './moduleConsentFormsGetters.js'

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}
