/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    selectedOrganization: state => state.selectedOrganization,
    organizationPaymentStatus: state => state.selectedOrganizationPaymentSetup,
    selectedOrganizationId: state => state.selectedOrganizationId,
    selectedOrganizationBillingEmail: state => state.selectedOrganizationBillingEmail,
}
