import axios from "@/axios";

export default {
  fetchHubspotSettings({ commit }, data) {
    return axios.get("/api/v1/hubspot/get-hubspot-settings", data);
  },
  fetchHubspotSetting({ commit }, id) {
    return axios.get("/api/v1/hubspot/get-hubspot-setting/" + id);
  },
  createHubspotSetting({ commit }, data) {
    return axios.post("/api/v1/hubspot/create-hubspot-setting", data);
  },
  updateHubspotSetting({ commit }, data) {
    console.log("data", data);
    return axios.post(
      "/api/v1/hubspot/update-hubspot-setting/" + data._id,
      data
    );
  },
  deleteHubspotSetting({ commit }, id) {
    return axios.delete("/api/v1/hubspot/delete-hubspot-setting/" + id);
  },
  getSupplierList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpSupplierList(data)
        .then(res => {
          console.log("get suppliers", res);
          return resolve(res);
        })
        .catch(err => {
          console.log("get error", err);
          return reject(err);
        });
    });
  },
  getAllSupplierList({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchHttpAllSupplierList()
        .then(res => {
          console.log("get suppliers", res);
          return resolve(res);
        })
        .catch(err => {
          console.log("get error", err);
          return reject(err);
        });
    });
  }
};
