import axios from "@/axios";

export default {
  saveFeedbackSurvey({commit}, data){
    return new Promise((resolve, reject) => {
      axios
        .post('/api/v1/store/feedbackSurvey/save', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
  updateFeedbackSurvey({commit}, data){
    return new Promise((resolve, reject) => {
      axios
      .post('/api/v1/store/feedbackSurvey/update', data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    })
  },
};
