import axios from "@/axios";

const OPENAI_PROTOCOL = process.env.VUE_APP_OPENAI_PROTOCOL;
const OPENAI_HOST = process.env.VUE_APP_OPENAI_HOST;
const OPENAI_PORT = process.env.VUE_APP_OPENAI_PORT;

const OPENAI_BASE_URL = `${OPENAI_PROTOCOL}://${OPENAI_HOST}:${OPENAI_PORT}`

export default {
  async askAI({ commit, state }, data) {

    const sessionId = state.currentSessionId
    const payload = {
      "openai_msg": {
        "message": {
          "user_id": data.userId,
          "message": data.question,
          "session_id": sessionId
        }
      }
    }
    
    const request = new Promise((resolve, reject) => {
      axios.post(`${OPENAI_BASE_URL}/api/v1/openai/session-chat`, payload)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
          setTimeout(() => {
            const errorMsg = {
              "message": "Sorry, I don't know how to help with that."
            }
            commit('MUTATE_CURRENT_CONVO_RESPONSE', errorMsg)
          }, 1000)
          
          reject(err);
      });
    });

    const result = await request;

    commit('MUTATE_CURRENT_CONVO_RESPONSE', result.data)
    commit('MUTATE_CURRENT_SESSION_ID', result.data.session_id)
  },
  async fetchAllSessionHistoryById({ commit }, data) {
    const payload = {
      params: {
        "user_id": data.userId
      }
    }

    const request = new Promise((resolve, reject) => {
      axios.get(`${OPENAI_BASE_URL}/api/v1/openai/history`, payload)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
          
          reject(err);
      });
    });
    const result = await request;

    commit('MUTATE_SESSION_HISTORY', result.data.message)
  },
  async fetchSessionHistoryConvoById({ commit, state }, data) {
    commit('MUTATE_IS_FETCHING_SESSION_HISTORY', true)
    commit('MUTATE_CLEAR_CONVO_ITEMS')
    
    const sessionId = state.currentSessionId

    const payload = {
      params: {
        "user_id": data.userId,
        "session_id": sessionId
      }
    }
    const request = new Promise((resolve, reject) => {
      axios.get(`${OPENAI_BASE_URL}/api/v1/openai/sessionHistory`, payload)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
          commit('MUTATE_CONVO_ITEMS', [])
          setTimeout(() => {
            commit('MUTATE_IS_FETCHING_SESSION_HISTORY', false)
          }, 1000)
          reject(err);
      });
    });

    const result = await request;
    const results = result.data.message
    results.map((history) => {
        if(history.role === 'user') {
          const currentConvo = { 'userId': history.user_id, 'question': history.message, 'response': '' };
          commit('MUTATE_ADD_CONVO_ITEM', currentConvo)
        } else if (history.role === 'ai') {
          commit('MUTATE_CURRENT_CONVO_RESPONSE', history)
        }
    })
    
    setTimeout(() => {
      commit('MUTATE_IS_FETCHING_SESSION_HISTORY', false)
    }, 1000)
  },
};
