import axios from "@/axios";

export default {
  fetchStoreAlertNotifications({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/storeAlertNotification/alerts?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createStoreAlertNotification({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/storeAlertNotification/create", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },

  updateStoreAlertNotificationDetails({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
      .put(`/api/v1/storeAlertNotification/updateDetails`, data)
      .then(res => {
        return resolve(res);
      })
      .catch(err => {
        return reject(err);
      });
    });
  },
  getStoreAlertNotificationDetails({ commit }, alertId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/details/" + alertId)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  deleteStoreAlertNotificationById({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
      .delete(`/api/v1/storeAlertNotification/delete/`+ data.id)
      .then(res => {
        return resolve(res);
      })
      .catch(err => {
        return reject(err);
      });
    });
  },
  fetchStoreAlertsBanner({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/store/banners" , data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreAlertsBannerByBrand({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/brand/banners", { params: {brandSlug: data}})
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreAlertsBannerByCategoryId({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/category/banners", { params: {categorySlug: data}})
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchStoreAlertsBannerByProductId({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/product/banners", { params: {productId: data}})
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchCheckoutStoreAlertsBanner({commit}, data) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/storeAlertNotification/checkout/banners", { params: {productId: data.productId}})
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
};
