export default [
  {
    path: "/supplier",
    redirect: "/supplier/insights",
    meta: {
      rule: "editor",
    },
    component: () => import("@/layouts/main/supplier/supplierMain.vue"),
    children: [
      {
        path: "insights",
        name: "SupplierInsights",
        component: () => import("@/views/superAdmin/insights/Insights.vue"),
        meta: {
          pageTitle: "Fresh Clinics | Insights",
          rule: "editor",
        }
      },
      {
        path: "insights/nurses",
        name: "SupplierInsightsNurses",
        component: () => import("@/views/superAdmin/insights/Nurses.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Nurses", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Nurses",
          rule: "editor",
        },
      },
      {
        path: "insights/patient-trends",
        name: "SupplierInsightsPatients",
        component: () =>
          import("@/views/superAdmin/insights/PatientTrends.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Patients", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Patient Trends",
          rule: "editor",
        },
      },
      {
        path: "insights/products",
        name: "SupplierInsightsProducts",
        component: () => import("@/views/superAdmin/insights/Products.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Products", active: true },
          ],
          pageTitle: "Fresh Clinics | Insights - Products",
          rule: "editor",
        },
      },
      {
        path: "insights/clinics",
        name: "SupplierInsightClinics",
        component: () => import("@/views/superAdmin/insights/Clinics.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Clinics", active: true },
          ],
          pageTitle: "Fresh Clinics | Insight - Clinics",
          rule: "editor",
        },
      },
      {
        path: "insights/treatment-area",
        name: "SupplierInsightTreatmentAreas",
        component: () =>
          import("@/views/superAdmin/insights/TreatmentAreas.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Treatment Area", active: true },
          ],
          pageTitle: "Fresh Clinics | Insight - Treatment Area",
          rule: "editor",
        },
        children: [
          {
            path: "single-vs-multiple",
            name: "SupplierInsightSingle",
            component: () =>
              import("@/views/superAdmin/insights/SingleVSMultiple.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                { title: "Single VS Multiple Treatment", active: true },
              ],
              pageTitle:
                "Fresh Clinics | Insight - Single Vs. Multiple Treatments",
              rule: "editor",
            },
            alias: "/",
          },
          {
            path: "all/treatments-per-treatment-area",
            name: "SupplierAverageTreatmentsPerTreatmentAreaAll",
            component: () =>
              import(
                "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
              ),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Number of Treatments per Treatment Area",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "toxin/treatments-per-treatment-area",
            name: "SupplierAverageTreatmentsPerTreatmentAreaToxin",
            component: () =>
              import(
                "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
              ),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Number of Treatments per Treatment Area",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "filler/treatments-per-treatment-area",
            name: "SupplierAverageTreatmentsPerTreatmentAreaFiller",
            component: () =>
              import(
                "@/views/superAdmin/insights/TreatmentsPerTreatmentArea.vue"
              ),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Number of Treatments per Treatment Area",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "toxin/average-treatment-by-gender-and-age",
            name: "SupplierAverageTreatmentByGenderAndAge",
            component: () =>
              import(
                "@/views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
              ),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Average Treatment By Gender And Age (TOXIN)",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "toxin/average-treatment-by-age",
            name: "SupplierAverageTreatmentByAge",
            component: () =>
              import("@/views/superAdmin/insights/AverageTreatmentByAge.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Average Treatment By Age (TOXIN)",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "all/average-treatment-by-area",
            name: "SupplierAverageTreatmentByAreaAll",
            component: () =>
              import("@/views/superAdmin/insights/AverageTreatmentByArea.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                { title: "Average Treatment By Area (ALL)", active: true },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "filler/average-treatment-by-area",
            name: "SupplierAverageTreatmentByAreaFiller",
            component: () =>
              import("@/views/superAdmin/insights/AverageTreatmentByArea.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                { title: "Average Treatment By Area (FILLER)", active: true },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "toxin/average-treatment-by-area",
            name: "SupplierAverageTreatmentByAreaToxin",
            component: () =>
              import("@/views/superAdmin/insights/AverageTreatmentByArea.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                { title: "Average Treatment By Area (TOXIN)", active: true },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "filler/average-treatment-by-gender-and-age",
            name: "SupplierAverageTreatmentByGenderAndAgeFiller",
            component: () =>
              import(
                "@/views/superAdmin/insights/AverageTreatmentByGenderAndAge.vue"
              ),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Average Treatment By Gender And Age (FILLER)",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "filler/average-treatment-by-age",
            name: "SupplierAverageTreatmentByAgeFiller",
            component: () =>
              import("@/views/superAdmin/insights/AverageTreatmentByAge.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/notice-board" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Average Treatment By Age (FILLER)",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "filler/treatment-by-nurse",
            name: "SupplierInsightsTreatmentByNurseFiller",
            component: () =>
              import("@/views/superAdmin/insights/TreatmentAreaByNurse.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/dashboard" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Treatment Area by Nurse",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
          {
            path: "toxin/treatment-by-nurse",
            name: "SupplierInsightsTreatmentByNurse",
            component: () =>
              import("@/views/superAdmin/insights/TreatmentAreaByNurse.vue"),
            meta: {
              breadcrumb: [
                { title: "Home", url: "/supplier/dashboard" },
                { title: "Insights", url: "/supplier/insights" },
                {
                  title: "Treatment Area by Nurse",
                  active: true,
                },
              ],
              pageTitle: "Fresh Clinics | Insight - Treatment",
              rule: "editor",
            },
          },
        ],
      },
      {
        path: "insights/appointments",
        name: "SupplierInsightClinics",
        component: () => import("@/views/superAdmin/insights/Appointments.vue"),
        meta: {
          breadcrumb: [
            { title: "Home", url: "/supplier/notice-board" },
            { title: "Insights", url: "/supplier/insights" },
            { title: "Appointments", active: true },
          ],
          pageTitle: "Fresh Clinics | Insight - Appointments",
          rule: "editor",
        },
      },
    ],
  },
];
