/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  filters: {
    brands: [],
    types: [],
    categories: [],
  },
  settings: {
    main_banner: {
      header: "",
      subHeader: "",
      image: ""
    },
    first_section: {
      header: "",
      tag: ""
    },
  },
  resources: {
    recent: {
      results: []
    },
    compliance: {
      results: []
    },
    searchResults: {
      results: []
    }
  },
}
