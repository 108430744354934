import "firebase/auth";
import axios from "@/axios";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  fetchNoticeBoardItems({ commit }, data) {
    return axios.get("/api/v1/prismic/getNoticeBoardItems");
  },
  fetchSupplierTrainingItems({ commit }, data) {
    return axios.get("/api/v1/prismic/getSupplierTrainingItems");
  },
  fetchAllResources({ commit }, data) {
    return axios.get("/api/v1/prismic/getResources");
  },
  fetchAllEvents({ commit }, data) {
    return axios.get("/api/v1/eventbrite/getEvents");
  },
  fetchPublishedProtocolsResources({ commit }, data) {
    return axios.get("/api/v1/prismic/getPublishedProtocolsResources");
  },
};
