import axios from "@/axios";

export default {
  fetchRoles({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          "/api/v1/rolesAndPermissions/fetchRoles?page=" +
            data.page +
            "&limit=" +
            data.limit +
            "&search=" +
            data.search +
            "&sort=" +
            data.sort +
            "&dir=" +
            data.dir
        )
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createRole({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("/api/v1/rolesAndPermissions/createRole", data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getRoleById({ commit }, roleId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/rolesAndPermissions/getRoleById?roleId=" + roleId)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  savePermissionForRoles({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/rolesAndPermissions/savePermissionForRoles`, data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getAllRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/v1/rolesAndPermissions/fetchAllRoles")
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};
