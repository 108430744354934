import axios from "@/axios";

export default {
  fetchTransactionLog({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/api/v1/transactionLog/fetch?year=${data.year}&month=${data.month}&search=${data.search}`
        )
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};
