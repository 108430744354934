/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  isFranchiseClinic: state => state.isFranchiseClinic,
  isDoctorClinic: state =>
    state.AppActiveUser.userRole === "doctorClinic" ? true : false
};
