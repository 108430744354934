/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import auth from "@/auth/authService";
import firebase from "firebase/app";
import "firebase/auth";
import { sortBy } from "lodash";
import utils from '@/assets/utils'

export default {
  chartData: {},
  table: {
    data: [],
    headers: ["Category", "Single", "Multiple", "Total Treatments"],
  },
  filters: {
    clnc_state: [],
    clnc_postcode: [],
    clnc_suburb: [],
    ptnt_state: [],
    ptnt_postcode: [],
    ptnt_suburb: [],
    brand: [],
    product: [],
    product_type: [],
    dose_ml: [],
    unit: [],
    clinic: [],
    org: [],
    area: [],
    doctor: [],
    nurse: [],
    gender: [],
    patient_state: [],
    treatment_state: [],
    quintile: [],
    age: [],
    number_of_days: [],
  },
  FILTERS_SELECTED: {
    clnc_state: [],
    clnc_postcode: [],
    clnc_suburb: [],
    ptnt_state: [],
    ptnt_postcode: [],
    ptnt_suburb: [],
    brand: [],
    product: [],
    product_type: [],
    dose_ml: [],
    unit: [],
    clinic: [],
    org: [],
    area: [],
    doctor: [],
    nurse: [],
    gender: [],
    patient_state: [],
    treatment_state: [],
    quintile: [],
    age: [],
    number_of_days: [],
    selectedDate: [utils.startDate(), new Date()],
    is_nurse: false,
    is_age: false,
    is_area: false,
    is_all_clinics: false,
    treatment_category: [],
  }
};
