/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // VxAutoSuggest

  UPDATE_STARRED_PAGE(state, payload) {
    // find item index in search list state
    const index = state.navbarSearchAndPinList["pages"].data.findIndex(
      item => item.url == payload.url
    );

    // update the main list
    state.navbarSearchAndPinList["pages"].data[index].is_bookmarked =
      payload.val;

    // if val is true add it to starred else remove
    if (payload.val) {
      state.starredPages.push(
        state.navbarSearchAndPinList["pages"].data[index]
      );
    } else {
      // find item index from starred pages
      const index = state.starredPages.findIndex(
        item => item.url == payload.url
      );

      // remove item using index
      state.starredPages.splice(index, 1);
    }
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },
  UPDATE_CLINIC_ID(state, val) {
    state.clinicId = val.clinicId;
    state.clinic = val.clinic;
    state.operations = val.operations;
  },
  UPDATE_CLINIC(state, val) {
    state.clinic = val;
  },
  SHOW_STORE(state, val) {
    state.showStore = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  UPDATE_USER_INFO(state, payload) {
    // Get Data localStorage
    let userInfo =
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ) || state.AppActiveUser;

    if (payload.xeroConnectionStatus)
      localStorage.setItem("xeroConnectionStatus", true);
    else localStorage.setItem("xeroConnectionStatus", false);

    if (payload) {
      payload.uid = payload._id;
      payload.displayName = payload.firstName + " " + payload.lastName;
      payload.photoURL =
        payload.profileImage || require("@/assets/images/profile/default.png");
      // payload.status = "online";
      payload.userRole = payload.userType;

      state.AppActiveUser = payload;

      userInfo.badge = payload.badge;
      userInfo.badgeCount = payload.badge;
      state.badgeCount = payload.badge;
    }

    // for (const property of Object.keys(payload)) {
    //   if (payload[property] != null) {
    //     // If some of user property is null - user default property defined in state.AppActiveUser
    //     state.AppActiveUser = payload[property];

    //     // Update key in localStorage
    //     userInfo[property] = payload[property];
    //   }
    // }
    // Store data in localStorage
    localStorage.setItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
      JSON.stringify(userInfo)
    );
  },

  UPDATE_USER_TEMP_PROFILE_INFO(state, payload) {
    if (payload) {
      state.AppActiveUser.tempProfile = payload.tempProfile;
    }
  },
  UPDATE_CURRENT_STORE_LIMITATION(state, payload) {
      state.currentStoreLimitation = payload;
  },
  // Updates user info in state and localstorage
  UPDATE_USER_BADGE_COUNT(state, payload) {
    // Get Data localStorage
    let userInfo =
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ) || state.AppActiveUser;

    if (payload && state.AppActiveUser) {
      state.AppActiveUser.badge = payload.badge;
      userInfo.badge = payload.badge;
      userInfo.badgeCount = payload.badge;
      state.badgeCount = payload.badge;
    }

    localStorage.setItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
      JSON.stringify(userInfo)
    );
  },
  UPDATE_XERO_CONNECTION_STATUS(state, payload) {
    // Get Data localStorage
    let userInfo =
      JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ) || state.AppActiveUser;

    state.AppActiveUser.xeroConnectionStatus = payload.xeroConnectionStatus;
    userInfo.xeroConnectionStatus = payload.xeroConnectionStatus;

    if (payload.xeroConnectionStatus)
      localStorage.setItem("xeroConnectionStatus", true);
    else localStorage.setItem("xeroConnectionStatus", false);

    localStorage.setItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
      JSON.stringify(userInfo)
    );
  },

  // update user permissions on run time
  UPDATE_USER_PERMISSION(state, payload) {
    if (payload) state.AppActiveUser.permissions = payload;
  }
};

export default mutations;
