import Vue from "vue";
import VueCookies from "vue-cookies";
import jwt_decode from "jwt-decode";
Vue.use(VueCookies);

export const auth = (to, from, next) => {
  const url = new URL(window.location.href);
  if (
    url.searchParams.get("userId") &&
    url.searchParams.get("userId") !== localStorage.getItem("userId")
  ) {
    localStorage.clear();
  }
  const token = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}AccessToken`
  );
  const isLoggedIn = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}LoggedIn`
  );
  const cookieToken = Vue.$cookies.get(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}RememberMeToken`
  );
  const cookieUser = Vue.$cookies.get(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}UserType`
  );
  const user = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`
  );

  const userObject = JSON.parse(user);

  if(window.smartlook && user) {
    window.smartlook('identify', userObject.id, {
      "name": userObject.firstName + ' ' + userObject.lastName,
      "email": userObject.email
    })
  }

  // Redirect if its a doctor and region is US
  if(userObject && userObject.userType === 'doctor' && process.env.VUE_APP_REGION === "US") {
    window.location = process.env.VUE_APP_DOCTOR_PORTAL;
    return;
  }

  const publicPages = [
    "/order-login",
    "/join-fresh-clinics",
    "/existing-members",
    "/login",
    "/select-workspace",
    "/register",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/set-password/" + to.params.token,
    "/pages/error-419",
    "/pages/error-404",
    "/pages/privacy-policy/" + to.params.slug,
    "/pages/terms-and-conditions/" + to.params.slug,
    "/pages/agreement/" + to.params.slug,
    "/drugbook",
    "/drugbook/entries",
    "/drugbook/setup",
    "/drugbook/setup/clinic",
    "/drugbook/article",
    "/how-to",
    "/p/" + to.params.sharedProductId
  ];

  const authRequired = !publicPages.includes(to.path);
  const role = localStorage.getItem(
    `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
  );
  var decoded ={}
  if(token){
    decoded = jwt_decode(token)
  }
  if (to.path.includes("/super-admin") && decoded.userType !== "superAdmin") {
    return next("/pages/error-403");
  } else {
    if (!authRequired && isLoggedIn && token) {
      const user = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      );

      if (to.path === "/select-workspace") {
        return next();
      }

      // access to how-to-guidelines page
      if (to.path === '/how-to') return next();

      if (role && role.toUpperCase() === "superAdmin".toUpperCase()) {
        return next("/super-admin/dashboard");
      }
      if (
        role &&
        (role.toUpperCase() === "franchise".toUpperCase() ||
          role === "subFranchise".toUpperCase())
      ) {
        return next("/franchise/notice-board");
      }
      if (
        role &&
        (role.toUpperCase() === "clinic".toUpperCase() ||
          role === "subClinic".toUpperCase())
      ) {
        return next("/clinic/notice-board");
      }

      if (role && role.toUpperCase() === "doctorClinic".toUpperCase()) {
        return next("/doctor-clinic/shop");
      }

      if (role && role.toUpperCase() === "supplier".toUpperCase()) {
        return next("/supplier/insights");
      }

      if (role && role.toUpperCase() === "org owner".toUpperCase()) {
        if(to.params.sharedProductId){
          return next("/org-owner/product/" + to.params.sharedProductId)
        }
        return next("/org-owner/home");
      }
      if (role && role.toUpperCase() === "dermaSpecialist".toUpperCase()) {
        return next("/derma-specialist/shop");
      }

      if (role && role.toUpperCase() === "doctor".toUpperCase()) {
        return next("/doctor/notice-board");
      }
      if (role && role.toUpperCase() === "nurse".toUpperCase()) {
        return next("/nurse/notice-board");
      }
      if (role && role.toUpperCase() === "admin".toUpperCase()) {
        return next("/admin/dashboard");
      }

      if (user.userType === "superAdmin") {
        if(to.params.sharedProductId){
          return next("/super-admin/store/product/" + to.params.sharedProductId)
        }
        return next("/super-admin/dashboard");
      }
      if (user.userType === "basic") {
        if(to.params.sharedProductId){
          return next("/basic/product/" + to.params.sharedProductId)
        }
        return next("/basic/notice-board");
      }
      if (user.userType === "franchise" || user.userType === "subFranchise") {
        return next("/franchise/notice-board");
      }
      if (user.userType === "clinic" || user.userType === "subClinic") {
        return next("/clinic/notice-board");
      }

      if (user.userType === "doctorClinic") {
        return next("/doctor-clinic/shop");
      }
      if (user.userType === "dermaSpecialist") {
        return next("/derma-specialist/shop");
      }

      if (user.userType === "doctor") {
        // Redirecting to /home creates an infite loop which causes max call stack error
        // return next("doctor/home");        
        if(to.params.sharedProductId){
          return next("/doctor/product/" + to.params.sharedProductId)
        }
        return next("doctor/notice-board");
      }
      if (user.userType === "nurse") {
        if(to.params.sharedProductId){
          return next("/nurse/product/" + to.params.sharedProductId)
        }
        return next("nurse/home");
      }
      if (user.userType === "admin") {
        return next("/admin/dashboard");
      }
    }

    if (authRequired && !isLoggedIn && !token) {
      if (from.path !== "/login") {
        return next("/login");
      }
    }

    if (
      !publicPages.includes(to.path) &&
      token &&
      to.path !== "/clinic-complete-profile"
    ) {
      const user = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      );

      if (typeof user.userType === "undefined") {
        return next("/login");
      }
      if (role) {
        // return next("/org-owner/staff");
        if (role && role.toUpperCase() === "supplier".toUpperCase()) {
          if (!to.path.includes("/supplier/")) {
            return next("/pages/error-404");
          }
        }
      } else if (user.userType === "superAdmin") {
        if (!to.path.includes("/super-admin/")) {
          return next("/pages/error-404");
        }
      } else if (user.userType === "subFranchise") {
        if (!to.path.includes("/franchise/")) {
          return next("/pages/error-404");
        }
      } else if (user.userType === "subClinic") {
        if (!to.path.includes("/clinic/")) {
          return next("/pages/error-404");
        }
      } else if (user.userType === "doctorClinic") {
        if (!to.path.includes("/doctor-clinic/")) {
          return next("/pages/error-404");
        }
      } else if (user.userType === "dermaSpecialist") {
        if (!to.path.includes("/derma-specialist/")) {
          return next("/pages/error-404");
        }
      } else {
        if (!to.path.includes("/" + user.userType + "/")) {
          return next("/pages/error-404");
        }
      }
    }
  }

  const userTypes = ["basic", "nurse", "doctor"]

  // role type checker, if role not match return to previous url
  // exclude pages-*, from login & no matched pages
  if (
    token &&
    authRequired &&
    isLoggedIn &&
    decoded &&
    userTypes.includes(decoded.userType) &&
    !to.path.includes("/pages") &&
    to.matched.length
  ) {
    let formattedRole = decoded.userType;
    if (role === 'Org Owner') formattedRole = "org-owner"
    if (role === 'Supplier') formattedRole = "supplier"

    if (
      !from.path.includes("/login") &&
      !to.path.includes(formattedRole)
    ) return next(from.path);
  }

  return next();
};
