/*=========================================================================================
  File Name: moduleEcommerceActions.js
  Description: Ecommerce Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt";
import axios from "@/axios";

export default {
  fetchFilterData({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchProductFilter(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  fetchProductDetail({ commit }, id) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchEcommerceProductDetail(id)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  productFilterListing({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchProductListing(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  toggleItemInWishList({ commit }, item) {
    commit("TOGGLE_ITEM_IN_WISH_LIST", item);
  },
  setCategoryData({ commit }, data) {
    commit("SET_CATEGORY_DATA", data);
  },

  toggleItemInCart({ getters, commit, dispatch }, item) {
    if (getters.isInCart(item._id)) {
      return dispatch("removeItemFromCart", item);
    } else {
      dispatch("additemInCart", item);
    }
  },

  removeItemFromCart({ commit }, item) {
    return new Promise((resolve, reject) => {
      jwt
        .removeProductStore(item.productStoreId)
        .then(res => {
          commit("REMOVE_ITEM_FROM_CART", item);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  removeInactiveItemFromCart({ commit }, item) {
    return new Promise((resolve, reject) => {
      jwt
        .removeProductStore(item.productStoreId)
        .then(res => {
          commit("REMOVE_INACTIVE_ITEM_FROM_CART", item);
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  additemInCart({ commit }, item) {
    
    const product = item.data;
    const minQty = item.minQty > 1 ? item.minQty : 1;

    product["availableQuantity"] = item.data.quantity;
    product["selectedQuantity"] = item.type === "decrement" ? -1 : minQty;

    // if (!item.selectedQuantity) {
    //   product.selectedQuantity = item.quantity;
    // }
    
    return new Promise((resolve, reject) => {
      jwt
        .addProductStoreInCart(product)
        .then(res => {
          commit("ADD_ITEM_IN_CART", res.data.data);
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });

    // Below properties should be added as per requirement from back-end
    // This is added just for demo purpose
    // item["discount_in_percentage"] = Math.floor(Math.random() * 20) + 4
    // item["offers_count"] = Math.floor(Math.random() * 4) + 1
    // item["delivery_date"] = new Date(new Date().getTime() + (Math.random()*10*24*60*60*1000)).toDateString().slice(0, -4)
  },
  additemInCartHomepage({ commit }, item) {
    
    const product = item.data;
    const minQty = item.minQty > 1 ? item.minQty : 1;

    product["availableQuantity"] = item.data.quantity;
    product["selectedQuantity"] = item.type === "decrement" ? -1 : minQty;

    // if (!item.selectedQuantity) {
    //   product.selectedQuantity = item.quantity;
    // }
    
    return new Promise((resolve, reject) => {
      jwt
        .addProductStoreInCart(product)
        .then(res => {
          commit("ADD_ITEM_IN_CART", res.data.data);
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });


    // Below properties should be added as per requirement from back-end
    // This is added just for demo purpose
    // item["discount_in_percentage"] = Math.floor(Math.random() * 20) + 4
    // item["offers_count"] = Math.floor(Math.random() * 4) + 1
    // item["delivery_date"] = new Date(new Date().getTime() + (Math.random()*10*24*60*60*1000)).toDateString().slice(0, -4)
  },
  getCartItemsFromApi({ commit }, id = null) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchCartItems(id) 
        .then(res => {
          commit("API_CART_ITEMS", {items: res.data.data, clinicId: sessionStorage.getItem("doctorClinicSelectedClinic")
          });
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateItemQuantity({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .updateProductStoreCartFromCheckout(payload.item)
        .then(({ data }) => {
          commit("UPDATE_ITEM_QUANTITY", { data: data.data });
          return resolve(data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  retryPayment({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .retryPayment(payload)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          console.log(err);
          return reject(err);
        });
    });
  },
  paymentForCartItems({ commit }, payload) {
    return new Promise((resolve, reject) => {
      jwt
        .paymentForCart(payload)
        .then(res => {
          if(res.data.data.requires_action || res.data.data.error) {
            return resolve({...res.data.data});
          }
          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}FirstName`,
            res.data.data.user.firstName
          );

          localStorage.setItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`,
            JSON.stringify(res.data.data.user)
          );

          commit("UPDATE_USER_INFO", res.data.data.user, { root: true });
          return resolve(res);
        })
        .catch(err => {
          console.log(err);
          return reject(err);
        });
    });
  },
  fetchOrderedList({ commit }, data) {
    console.log("************ fetch doctor is called");
    return new Promise((resolve, reject) => {
      jwt
        .fetchOrderedProductList(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFailedPayments({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .hasFailedPayments(sessionStorage.getItem("doctorClinicSelectedClinic"))
        .then(res => {
          resolve(res.data.data);
        })
        .catch(err => {
          reject(false);
        });
    });
  },
  fetchOrderDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchOrderDetailApi(data.orderId, data.clinicId)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  updateOrderDetail({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .updateOrderStatusApi(data.orderId, { status: data.status })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  orderPayment({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .orderPaymentApi(data.orderId, { status: data.status })
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendEnquiry({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .sendEnquiry(data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getMinimumOrderSuppliers({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchSupplierMinimumOrder()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  getRequirementMinimumOrder({ commit }) {
    return new Promise((resolve, reject) => {
      jwt
        .fetchMinimumOrderRequirement()
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  createMinimumOrder({ commit }, data) {
    return new Promise((resolve, reject) => {
      jwt
        .createMinimumOrder(data)
        .then(res => {
          return resolve(res);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  updateSearchText({ commit }, text){
    commit("UPDATE_SEARCH_TEXT", text); 
  },
  resetSearchText({ commit }){
    commit("UPDATE_SEARCH_TEXT", ""); 
  },
  algoliaSync({ commit }) {
    return axios
      .get("/api/v1/webhooks/algolia/sync")
      .then(res => {
        return resolve(res);
      })
      .catch(err => {
        return reject(err);
      });
  },
  async getHomepageProductsByIds({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/store/homepageTemplate/product/list", { params: { ids: data.ids, clinicId: data.clinicId, isPreviewPage: data.isPreviewPage } } );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async getJsonSettings({ commit }, data) {
    const { slug } = data;
    try {
      const response = await axios.get("/api/v1/store/jsonSettings", { params: { slug } } );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async saveJsonSettings({ commit }, data) {
    const { value, slug } = data;
    try {
      const response = await axios.post("/api/v1/store/jsonSettings/save", { value },{params: {slug}} );
      return response;
    } catch (error) {
      throw error;
    }
  },
  fetchProductByCategory({ commit }, slug) {
    return axios.get('api/v1/store/products/byCategory?slug=' + slug);
  },
  orderAgain({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v1/inventory/order-again", data)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
  async getProductOrderByCategory({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/store/products/ordersByCategory?categoryId=" + data );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async saveFeedback({ commit }, data) {
    try {
      const response = await axios.post("/api/v1/store/feedbackSurvey/save", data );
      return response;
    } catch (error) {
      throw error;
    }
  },
  uploadMedia({ commit }, data) {
    let path = "/api/v1/store/jsonSettings/uploadMedia";
    return new Promise((resolve, reject) => {
      let config = {
        header: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(path, data, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async getBrandJsonSettings({ commit }, data) {
    const { brandId } = data;
    try {
      const response = await axios.get("/api/v1/store/brandJsonSettings", { params: { brandId } } );
      return response;
    } catch (error) {
      throw error;
    }
  },
  async saveBrandJsonSettings({ commit }, data) {
    const { value, brandId } = data;
    try {
      const response = await axios.post("/api/v1/store/brandJsonSettings/save", { value },{ params: { brandId }} );
      return response;
    } catch (error) {
      throw error;
    }
  },
  validateTCProducts({commit}, data) {
    return new Promise((resolve, reject) => {
      axios.get(`/api/v1/store/products/validateTCProducts/${data.orderId}`)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        })
    })
  },
};
